import axios from "./axios"
import JSONbig from "json-bigint"
// 封装get请求
export function get(url, params = {}) {
  return axios({
    method: "get",
    url,
    params,
    transformResponse: data => {
      return JSONbig.parse(data)
    }
  })
}
// 封装post请求
export function post(url, data = {}) {
  return axios({
    method: "post",
    url,
    data
  })
}