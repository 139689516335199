import Vue from 'vue'
import VueRouter from 'vue-router'
import { getNav } from '../utils/nav'
import store from "../store/index"
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'// nprogress样式文件
// 进度条配置
NProgress.configure({     
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})
Vue.use(VueRouter)
let originpush = VueRouter.prototype.push
// 重写路由跳转方法，防止重复点击同一路由报错
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originpush.call(this, location, resolve, reject)
  } else {
    originpush.call(this, location, () => { }, () => { })
  }
}
const routes = [
  {
    path: '/',
    redirect: "/auth",
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("../views/auth.vue")
  },
  {
    path:"/home",
    name:"home",
    component:() => import("../views/home.vue"),
    redirect:'/index',
    children:[
      {
        path: '/index',
        name: "index",
        component: () => import("../views/index.vue")
      },
    ]
  },
  {
    name: "question",
    path: "/question",
    component: () => import("../views/question.vue")
  },
  {
    name: "userguide",
    path: "/userguide",
    component: () => import("../views/userguide.vue")
  },
  {
    path:"/loginFail",
    name:"loginFail",
    component:()=>import("../views/loginFail.vue")
  }
]
routes[2].children.push(...getNav())
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start();
  store.state.show = true
  next()
})
router.afterEach(()=>{
  window.scrollTo(0,0)
  // 关闭进度条
  setTimeout(() => {
    NProgress.done()
  }, 500);
})
export default router
