import axios from "axios";
import { message } from "../assets/js/resetMessage";
import Qs from 'qs'
const myaxios = new axios.create({})

myaxios.defaults.timeout = 60000
//请求拦截器
myaxios.interceptors.request.use((config) => {
  config.headers["X-Requested-With"] = 'XMLHttpRequest'
  let noTokenUrl = ["shop/Login/isToken", "shop/Upload_Log/loginLogError"]//不需要token的请求
  if (!noTokenUrl.includes(config.url)) {
    config.headers.token = localStorage.getItem("token")
  }
  config.data = Qs.stringify(config.data)//参数序列化
  config.url = "https://tbcrmapi.niuniucrm.com/" + config.url
  return config
})
// 响应拦截器
myaxios.interceptors.response.use((res) => {
  if (res.status == 200) {
    if (res.data.code == 1) {//成功返回数据
      return Promise.resolve(res.data)
    } else if (res.data.code == 0) {
      if (res.config.url.includes("shop/Crowd/pollingCrowdLog")) {//查看人群发送消息状态
        return Promise.reject(res.data)
      }
      message({
        message: res.data.msg || "未知错误！",
        type: 'warning',
      })
      return Promise.reject(res)
    } else {
      if (res.config.url.includes("shop/pay/add")) {//支付宝支付返回表单数据
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(res)
      }
    }
  } else {
    return Promise.reject(res);
  }
}, err => {
  let status = err?.response?.status
  if (err.response) {
    if (status === 401) {
      let { data, msg } = err.response.data
      message({
        message: msg,
        type: 'error',
        onClose() {
          localStorage.clear()
          sessionStorage.clear()
          setTimeout(() => {
            window.location.href = data;
          }, 2000)
        }
      })
      return
    }
    if (status === 404) return message.error("Status:404，正在请求不存在的服务器记录！")
    if (status === 500) return message.error("Status:500，服务器发生错误！")
    message.error(err.message || `Status:${status}，未知错误！`)
  } else {
    message.error("请求服务器无响应！")
  }
})
export default myaxios