import navlist from "../components/leftnav/leftnav.json"
let list = navlist.nav
// 处理json路由信息
export const getNav = function () {
  let routes = []
  for (const key in list) {
    let father = list[key]
    let { path, meta, redirect } = father
    let obj = {
      path,
      redirect,
      meta,
      children: []
    }
    if(path) obj.component = () => import(`../views${path}.vue`)
    for (let i = 0; i < list[key].child.length; i++) {
      let son = list[key].child[i]
      let route = {
        path: son.path,
        name: son.name,
        meta: son.meta,
        component: () => import(`../views${son.path}.vue`)
      }
      obj.children.push(route)
    }
    routes.push(obj)
  }
  return routes
}